import React, { useEffect, useState } from "react";
import classNames from "./stafftable.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { allNav } from "../../../../assets/data/constant";
import { FaCaretDown } from "react-icons/fa";
import breakdown from "../../../../assets/images/pages/teams/breakdown.svg";
import lockIcon from "../../../../assets/images/icons/lock.svg";
import cpuIcon from "../../../../assets/images/icons/cpu.svg";
import axios from "axios";

const StaffTable = ({
  allStaff,
  globalSearchQuery,
  roleType,
  setcalendarinfo,
  setsendcalender,
  seteditingToggle,
  setitemsend,
  sidebarOpen,
  allLeadsLoading,
  allHeading,
}) => {
  const history = useNavigate();

  return (
    <div className={classNames.tableContainer}>
      <table className={classNames.staffTable}>
        <thead>
          <tr
            className={classNames.staffHead}
            style={{
              gridTemplateColumns:
                roleType === "staffgroups"
                  ? "min-content repeat(4, 1fr)"
                  : roleType === "staffroles"
                  ? "min-content repeat(4, 1fr)"
                  : roleType === "calendar"
                  ? "min-content repeat(4, 1fr)"
                  : "min-content repeat(5, 1fr)",
            }}
          >
            {allHeading?.map((eachHeading, index) => {
              return <th key={eachHeading + index}>{eachHeading}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr
              key={"loading"}
              style={{
                gridTemplateColumns:
                  roleType === "staffgroups"
                    ? "min-content repeat(4, 1fr)"
                    : roleType === "staffroles"
                    ? "min-content repeat(4, 1fr)"
                    : roleType === "calendar"
                    ? "min-content repeat(4, 1fr)"
                    : "min-content repeat(5, 1fr)",
              }}
            >
              <td>
                <Skeleton width={100} height={20} />
              </td>
              <td>
                <Skeleton width={100} height={20} />
              </td>
              <td>
                <Skeleton width={100} height={20} />
              </td>
              <td>
                <Skeleton width={100} height={20} />
              </td>
              {roleType === "staff" && (
                <td>
                  <Skeleton width={100} height={20} />
                </td>
              )}
            </tr>
          ) : (
            allStaff
              ?.filter((row) => {
                const searchQuery = globalSearchQuery?.toLowerCase();
                return roleType === "staffgroups"
                  ? row?.groupName?.toLowerCase()?.includes(searchQuery)
                  : roleType === "staffroles"
                  ? row?.name?.toLowerCase()?.includes(searchQuery)
                  : roleType === "staff"
                  ? row?.UserDetails?.length > 0 &&
                    row?.UserDetails[0]?.UserDetails?.length > 0
                    ? row?.UserDetails[0]?.UserDetails[0]?.name
                        ?.toLowerCase()
                        ?.includes(searchQuery)
                    : ""
                  : roleType === "calendar"
                  ? row?.CalendarName?.toLowerCase()?.includes(searchQuery)
                  : row?.name?.toLowerCase()?.includes(searchQuery);
              })
              ?.sort((a, b) => a?.first_name?.localeCompare(b?.first_name))
              ?.map((row, index) => {
                return (
                  <tr
                    onClick={() => {
                      if (roleType === "calendar") {
                        setcalendarinfo(row);
                        seteditingToggle(true);
                        setsendcalender(row);
                        history(`/owner/teams/calendar/${row?._id}`);
                        //  setcalendardrawer(true)
                        console.log("2883842843289", row);
                      } else if (roleType === "staffroles") {
                        seteditingToggle(true);
                        setitemsend(row);
                        history(`/owner/teams/staffroles/${row?._id}`);
                      } else if (roleType === "staffgroups") {
                        seteditingToggle(true);
                        setitemsend(row);
                        history(`/owner/teams/staffgroups/${row?._id}`);
                      } else if (roleType === "staff") {
                        seteditingToggle(true);
                        history(`/owner/teams/staff/${row?.TeamPlayerID}`);
                        //  setstaffitem(row?.TeamPlayerID)
                      }
                    }}
                    key={row?.brokerage_name + index}
                    style={{
                      gridTemplateColumns:
                        roleType === "staffgroups" && roleType == "calendar"
                          ? "min-content repeat(4, 1fr)"
                          : "min-content repeat(5, 1fr)",
                      pointerEvents: sidebarOpen ? "none" : "",
                    }}
                  >
                    <td>
                      {roleType === "staffgroups"
                        ? row?.groupName
                        : roleType === "staffroles"
                        ? row?.name
                        : roleType === "staff"
                        ? row?.UserDetails?.length > 0 &&
                          row?.UserDetails[0]?.UserDetails?.length > 0
                          ? row?.UserDetails[0]?.UserDetails[0]?.name
                          : ""
                        : roleType === "calendar"
                        ? row?.CalendarName
                        : row?.name}
                    </td>
                    {roleType !== "staffgroups" &&
                      roleType != "staffroles" &&
                      roleType != "calendar" && (
                        <td>
                          {roleType === "staffgroups"
                            ? row?.groupType
                            : roleType === "staffroles"
                            ? row?._id
                            : roleType === "staff"
                            ? row?.UserDetails?.length > 0 &&
                              row?.UserDetails[0]?.UserDetails?.length > 0
                              ? row?.UserDetails[0]?.UserDetails[0]?.email
                              : ""
                            : roleType === "calendar"
                            ? row?.TeamCalendarID
                            : row?.email}
                        </td>
                      )}
                    <td>
                      {roleType === "staffgroups"
                        ? row?.groupDesc
                        : roleType === "staffroles"
                        ? row?.description
                        : roleType === "staff"
                        ? row?.UserDetails?.length > 0 &&
                          row?.UserDetails[0]?.UserDetails?.length > 0
                          ? row?.UserDetails[0]?.UserDetails[0]?.PhoneNumber
                          : ""
                        : roleType === "calendar"
                        ? row?.CalendarDescription
                        : row?.PhoneNumber}
                    </td>
                    <td>
                      {roleType === "staffgroups" &&
                      row?.StaffRoleDetails?.length > 0
                        ? row?.StaffRoleDetails?.map((eachItem, index) => {
                            return (
                              <div key={eachItem?.name + index}>
                                {eachItem?.name}
                              </div>
                            );
                          })
                        : roleType === "staffroles"
                        ? row?.staffGroupCount
                        : roleType === "staff"
                        ? row?.UserDetails?.length > 0 &&
                          row?.UserDetails[0]?.UserDetails?.length > 0
                          ? row?.UserDetails[0]?.UserDetails[0]?.Gender
                          : ""
                        : roleType === "calendar"
                        ? row?.TimeZone
                        : row?.key}
                    </td>
                    <td>
                      {roleType === "staffgroups" &&
                      row?.modules?.length > 0 ? (
                        <div>
                          {row?.modules?.map((eachitem, index) => {
                            return <div key={eachitem + index}>{eachitem}</div>;
                          })}
                        </div>
                      ) : roleType === "staffroles" ? (
                        row?.staffCount
                      ) : roleType === "calendar" ? (
                        row?.staffCount
                      ) : roleType === "staff" ? (
                        row?.UserDetails?.[0]?.UserDetails?.[0]?.Address
                      ) : (
                        row?.userid
                      )}
                    </td>
                  </tr>
                );
              })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default StaffTable;

export const StaffProfile = () => {
  const { id } = useParams();
  const history = useNavigate();
  const [selectedSection, setSelectedSection] = useState("Dashboard");
  const [profileData, setProfileData] = useState({});

  //functions

  function getProfileData() {
    axios
      .get(
        `https://finacebackend.marketsverse.com/api/getTeamPlayerProfile?TeamPlayerID=${id}`
      )
      .then((res) => {
        if (res.data.success) {
          setProfileData(res?.data?.data?.[0]);
        } else {
          setProfileData([]);
        }
      });
  }

  //renderings

  useEffect(() => {
    getProfileData();
  }, [id]);

  return (
    <div className={classNames.staffProfile}>
      <div className={classNames.leftContainer}>
        <div className={classNames.allSections}>
          {allNav?.map((eachNav, index) => {
            return (
              <div
                key={eachNav.name + index}
                className={
                  selectedSection === eachNav?.name &&
                  classNames.selectedSection
                }
              >
                <div>{eachNav?.name}</div>
              </div>
            );
          })}
        </div>
        <div
          className={classNames.gradientBtn}
          onClick={() => history("/owner/teams/staff")}
        >
          Go Back
        </div>
      </div>
      <div className={classNames.rightContainer}>
        <div className={classNames.personalDetails}>
          <div className={classNames.profile}>
            <img src="" alt="" />
            <span>Shorupan Pirakaspathy</span>
          </div>
          <div className={classNames.details}>
            <div>
              <div className={classNames.title}>Email</div>
              <div className={classNames.value}>shorupan@gmail.com</div>
            </div>
            <div>
              <div className={classNames.title}>Phone</div>
              <div className={classNames.value}>+1 647 559 0183</div>
            </div>
            <div>
              <div className={classNames.title}>Status</div>
              <div className={classNames.value}>Clocked In</div>
            </div>
            <div>
              <div className={classNames.title}>Efficiency Score</div>
              <div className={classNames.value}>75.5%</div>
            </div>
          </div>
        </div>
        <div className={classNames.countContainer}>
          <div className={classNames.title}>Tasks</div>
          <div className={classNames.counts}>
            <div>
              <div className={classNames.title}>Tasks Assigned</div>
              <div className={classNames.value}>0</div>
            </div>
            <div>
              <div className={classNames.title}>Tasks Received</div>
              <div className={classNames.value}>0</div>
            </div>
          </div>
        </div>
        <div className={classNames.countContainer}>
          <div className={classNames.title}>Jobs</div>
          <div className={classNames.counts}>
            <div>
              <div className={classNames.title}>Jobs Assigned</div>
              <div className={classNames.value}>0</div>
            </div>
            <div>
              <div className={classNames.title}>Jobs Received</div>
              <div className={classNames.value}>0</div>
            </div>
          </div>
        </div>
        <div className={classNames.breakDown}>
          <div className={classNames.header}>
            <div className={classNames.title}>Work Breakdown</div>
            <div className={classNames.dropdown}>
              By Client <FaCaretDown />
            </div>
          </div>
          <div
            className={classNames.imageLock}
            style={{ justifyContent: "left" }}
          >
            <img src={breakdown} alt="breakdown" style={{ width: "65%" }} />
            <div className={classNames.lockDiv}>
              <img src={lockIcon} alt="lockIcon" />
              <div>Upgrade to unlock live analytics</div>
            </div>
          </div>
        </div>
        <div className={classNames.breakDown}>
          <div className={classNames.header}>
            <div className={classNames.title}>Tasks</div>
          </div>
          <div className={classNames.imageLock}>
            <div
              className={classNames.lockDiv}
              style={{ position: "relative" }}
            >
              <img src={cpuIcon} alt="cpuIcon" style={{ height: "4.5rem" }} />
              <div>There is nothing in your to do list at the moment </div>
              <span>View your task for tommorw</span>
            </div>
          </div>
        </div>
        <div className={classNames.breakDown}>
          <div className={classNames.header}>
            <div className={classNames.title}>Jobs</div>
          </div>
          <div className={classNames.imageLock}>
            <div
              className={classNames.lockDiv}
              style={{ position: "relative" }}
            >
              <img src={cpuIcon} alt="cpuIcon" style={{ height: "4.5rem" }} />
              <div>There is nothing in your to do list at the moment </div>
              <span>View your task for tommorw</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
